<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                     <v-btn
                            color="primary"
                            dark
                            class="mb-5"
                            @click="newSegment">
                                Nuevo Categoria
                        </v-btn>
                    <v-subheader>Selecciona un cliente para editar sus categorias</v-subheader>
                    <v-data-table
                        :headers="headers"
                        :items="Categories"
                        :loading="loadingCategories"                       
                        hide-default-footer
                        class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Categorias por clientes</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:no-data>
                            No se encontraron registros.
                        </template>
                       <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="viewItem(item.id)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="ProductsItem(item.id)"
                            >
                                mdi-eye
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="deleteItem(item.id)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                            v-model="currentPage"
                            :length="total_pages"
                            @input="handlePageChange"
                            :total-visible="10"
                        ></v-pagination>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
    data: () => ({
        headers: [
        {
          text: 'Nombre de la categoria',
          align: 'start',
          value: 'tag',
        },
        { text: 'Segmento', value: 'name_architecture' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
     
      loadingCategories: true,
      Categories: [],
      total_pages: 0,
      currentPage: 1
    }),

    mixins:[misMixins],

    methods: {
         handlePageChange(value) {
            this.currentPage = value;
            this.getCategories()
        },
        newSegment(){
            this.$router.push('newCategory')
        },
        createCliente(){
            this.$router.push({ name: 'CreateClient' });
            
        }
        ,viewItem(id){
            console.log('prueba id',id)
            this.$router.push({ name: 'Category', params:{ id: id}});
        },
        ProductsItem(id){
            console.log('prueba id',id)
            this.$router.push({ name: 'Products', params:{ id: id}});
        },

         deleteItem(id){

            this.$swal.fire({
                title: 'Está seguro de Eliminar esta categoría?, se eliminara los graficos, mapas y demas información asociada a esta.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                denyButtonText: `Cancelar`,
                icon: 'warning',
                }).then((result) => {
                
                if (result.isConfirmed) {

                    this.LoadingOpen();

                    this.$http.delete('architecture_tags/'+ id,
                    {
                        headers:{"Authorization": 'Bearer '+this.getCrypto()
                    }
                    }).then(response => {
                            this.getCategories();
                            this.LoadingClose();

                            this.SucessAlert(
                                'Eliminada',
                                'categoría eliminada con éxito'
                            )
                    }).catch(error => {
                        this.LoadingClose();
                    
                        this.ErrorAlert('Error al eliminar la categoría')
                    })

                } 
                })


        },
     

        getCategories(){
            this.loadingCategories = true
            this.$http.get('dimensions/categories/'+this.getClientSelect().id + '?page=' + this.currentPage).then(response => {
                this.Categories = response.data.data
                console.log('categories', this.Categories)
                this.total_pages = response.data.total_pages

                this.loadingCategories = false   
            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                // console.log('ERROR AL CONSULTAR LAS CATEGORIAS', error)
            })
        },

    },
    
    beforeMount(){
        this.getCategories()
        
    }
}
</script>