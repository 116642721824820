export const misMixins = {

    methods:{

        LoadingOpen(){
            this.$swal({
                title: 'Cargando!',
                text: 'Espere un momento por favor..'
           });

            this.$swal.showLoading()

        },

        LoadingClose(){
            this.$swal.close();
            this.$swal.hideLoading();
        },

        SucessAlert(action,message){
            this.$swal(
                action,
                message,
                'success'
            )
        },

        ErrorAlert(message){
            this.$swal(
                'Error',
                message,
                'error'
            )
        },

        ToastError(message){

            this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                icon: 'error',
                title: message
              })
        }
    }

}